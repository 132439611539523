import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import DownloadAppPage from "../components/download-app-page";
import authentication from "../../auth/react-azure-adb2c";
import { getItem, codeKey, B2CErrorsKey } from "../helpers/sessionStorage";
import registerLoginOnboarding from "../actions/login";
import { validateHcfStaffCodeForConsent, validateSlhStaffCode } from "../actions/validate-code";
import { isCodeValidated } from "../reducers/validate-code";
import BasePageLayout from "../components/base-page-layout";

class DownloadAppScreen extends Component {
  constructor() {
    super();
    this.setState({ loginLoaded: false, validateCodeLoaded: false, redirect: null })
  }

  componentDidMount() {
    const {
      registerLoginOnboarding: registerLoginOnboardingAction,
      validateHcfStaffCodeForConsent: validateHcfStaffCodeForConsentAction,
      validateSlhStaffCode: validateSlhStaffCodeAction
    } = this.props;

    if (authentication.signedIn()) {
      // don't care if registerLoginOnboarding fails, keep going anyway
      registerLoginOnboardingAction().finally(() => this.setState({ loginLoaded: true }));
      if (process.env.REACT_APP_WEBSITE_PARTNER_CODE === "SLH") {
        validateSlhStaffCodeAction(getItem(codeKey)).finally(() => this.setState({ validateCodeLoaded: true }));
      }
      else if (process.env.REACT_APP_WEBSITE_PARTNER_CODE === "HCF") {
        validateHcfStaffCodeForConsentAction(getItem(codeKey)).finally(() => this.setState({ validateCodeLoaded: true }));
      }
    }
    else {
      this.setState({ loginLoaded: true, validateCodeLoaded: true, redirect: "/" });
    }
  }

  render() {
    const { codeValidated } = this.props;
    const hasErrors = !!getItem(B2CErrorsKey);
    if (this.state?.loginLoaded && this.state?.validateCodeLoaded) {
      return (
        this.state?.redirect ? <Redirect to={this.state.redirect} />
          : (
              <BasePageLayout
                loading={hasErrors}
                content={<DownloadAppPage
                  isHcfStaffCode={codeValidated && process.env.REACT_APP_WEBSITE_PARTNER_CODE === "HCF"}
                  isSlhStaffCode={codeValidated && process.env.REACT_APP_WEBSITE_PARTNER_CODE === "SLH"}
                  codeKey={getItem(codeKey)}
                />}
              />
          )
      );
    }
    else {
      return(
        <BasePageLayout loading />
      );
    }
  }
}

export default (connect((state) => ({
  codeValidated: isCodeValidated(state),
}),
{ registerLoginOnboarding, validateHcfStaffCodeForConsent, validateSlhStaffCode, isCodeValidated })(DownloadAppScreen));

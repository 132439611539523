import React from "react";
import { AppBar } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

//import "../../wellbeing.css";

const useStyles = makeStyles(() => ({
  header: {
    backgroundColor: "white",
    height: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: 32,
    color: "#572c5f",
    fontWeight: "bold",
  },

  title: {
    textAlign: "center",
    fontFamily: "Rubik, sans-serif",
  },

  "header-offset": {
    width: 210,
  },

  stLukesLogo: {
    marginLeft: 10,
    width: 200,
  }
}));

const WellbeingHeader = () => {
  const classes = useStyles();

  return (
    <AppBar position="static" >
      <div className={classes.header}>
        <img className={classes.stLukesLogo} src="/assets/images/SLH/logo.png" alt="St.LukesHealth" />
        <div className={classes.title}>Wellbeing Platform</div>
			  <div className={classes["header-offset"]}></div>
      </div>
    </AppBar>
  );
};
export default WellbeingHeader;

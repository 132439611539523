import React, { useEffect } from "react";
import authentication from "../../auth/react-azure-adb2c";
import { SendStartedSignInEvent, SendStartedForgotPasswordEvent } from "../helpers/amplitude-analytics";
import adb2cConfig from "../../auth/adb2cConfig";
import { getItem, forgotPasswordKey } from "../helpers/sessionStorage";
import BasePageLayout from "../components/base-page-layout";

const LoginScreen = () => {
  useEffect(() => {
    if (!authentication.signedIn()) {
      if (getItem(forgotPasswordKey)) {
        SendStartedForgotPasswordEvent();
      } else {
        SendStartedSignInEvent();
      }
      authentication.login(adb2cConfig);
    }
  }, []);

  return (
    <BasePageLayout loading />
  );
}

export default LoginScreen;

/* eslint-disable no-console */
/* eslint-disable no-new */
// note on window.msal usage. There is little point holding the object constructed by new Msal.UserAgentApplication
// as the constructor for this class will make callbacks to the acquireToken function and these occur before
// any local assignment can take place. Not nice but its how it works.
import * as Msal from "msal";
import React from "react";
import { isEmpty } from "lodash";

import { SendStartedSignUpEvent } from "../app/helpers/amplitude-analytics";
import { getItem, setItem, removeItem, codeKey, idKey, emailKey, B2CErrorsKey, authorisingKey, forgotPasswordKey, removeB2CParamItems } from "../app/helpers/sessionStorage";

function loggerCallback(_, message) {
  console.log(message);
}

const logger = new Msal.Logger(loggerCallback, { level: Msal.LogLevel.Warning });
const state = {
  stopLoopingRedirect: false,
  launchApp: null,
  accessToken: null,
  idToken: null,
  scopes: {
    scopes: [],
  },
};

function getScopes() {
  return {
    ...state.scopes,
    extraQueryParameters: {
      validationCode: getItem(codeKey),
      validationId: getItem(idKey),
      prefill_email: getItem(emailKey),
      loginUrl: `${window.location.origin}/login`,
    },
  };
}

function signedIn() {
  const localMsalApp = window.msal;
  const user = localMsalApp.getAccount(state.scopes);
  if (user) {
    removeItem(B2CErrorsKey);
    removeItem(authorisingKey);
    removeItem(forgotPasswordKey);
  }

  return user;
}

function login(config) {
  setItem(authorisingKey, true);
  // if click the cancel button in the forget password page, the instance is changed to login.microsoftonline.com in somewhere
  if(window.msal.authority.indexOf("login.microsoftonline.com") > -1) {
    window.msal.authority = `${config.instance}${config.tenant}/${config.signInPolicy}`;
  }
  const localMsalApp = window.msal;

  localMsalApp.loginRedirect(getScopes());
}

function register(config) {
  if (!getItem(authorisingKey)) {
    SendStartedSignUpEvent();
  }
  setItem(authorisingKey, true);
  window.msal.authority = `${config.instance}${config.tenant}/${config.signUpPolicy}`;
  const localMsalApp = window.msal;
  localMsalApp.loginRedirect(getScopes());
}

function acquireToken(successCallback) {
  const localMsalApp = window.msal;
  const user = localMsalApp.getAccount(state.scopes);

  if (!user) {
    if (state.launchApp) {
      state.launchApp();
    }
  } else {
    localMsalApp.acquireTokenSilent(getScopes()).then((authResponse) => {
      
      state.accessToken = authResponse.accessToken;
      state.idToken = !isEmpty(authResponse.idToken) ? authResponse.idToken.rawIdToken : null;
      if (state.launchApp) {
        state.launchApp();
      }
      if (successCallback) {
        successCallback();
      }
    }, (error) => {
      if (error) {
        localMsalApp.acquireTokenRedirect(getScopes());
      }
    });
  }
}

function refreshAccessToken() {
  const localMsalApp = window.msal;
  return localMsalApp.acquireTokenSilent(getScopes()).then((authResponse) => {
    state.accessToken = authResponse.accessToken;
    state.idToken = !isEmpty(authResponse.idToken) ? authResponse.idToken.rawIdToken : null;
    return authResponse.accessToken;
  }, (error) => {
    if (error) {
      localMsalApp.acquireTokenRedirect(getScopes());
    }
    return state.accessToken;
  });
}

function logout() {
  sessionStorage.clear();
  window.msal.logout();
}

const authentication = {
  initialize: (config) => {
    const instance = config.instance ? config.instance : "https://login.microsoftonline.com/tfp/";
    let authority = null;

    const signinAuthority = `${instance}${config.tenant}/${config.signInPolicy}`;
    const forgotPasswordAuthority = `${instance}${config.tenant}/${config.forgotPasswordPolicy}`;
    let { redirectUri } = config;
    if (window.location.hash.indexOf("AADB2C90118") > -1) {
      authority = forgotPasswordAuthority;
      window.location.hash = "";
      // set a forgotPassword item, if true then login will be called again
      setItem(forgotPasswordKey, true);
    } else if (window.location.hash.indexOf("AADB2C90091") > -1) {
      // If Cancel is clicked during MFA, we start the B2C flow again
      window.location.hash = "";
    } else if (window.location.hash.indexOf("AADB2C90077") > -1) {
      // If an error is detected in SSO management (usually through the use of an iFrame)
      if (window.location.hash.indexOf("error") > -1) {
        setItem(B2CErrorsKey, window.location.hash);
        // if has error, remove the code from the session storage

        redirectUri = `${window.location.origin}/error`;
      }
    } else {
      if (window.location.hash.indexOf("error") > -1) {
        setItem(B2CErrorsKey, window.location.hash);
        // if has error, remove the code from the session storage

        removeB2CParamItems();
        redirectUri = `${window.location.origin}/error`;
      }
      authority = signinAuthority;
    }

    const validateAuthority = (config.validateAuthority !== null || undefined) ? config.validateAuthority : true;
    const { scopes } = config;

    function authCallback(authError) {
      window.location.hash = "";
      window.location.href = window.msal.getRedirectUri();
      const errorDesc = JSON.stringify(authError);
      removeItem(authorisingKey);
      if (errorDesc) {
        state.stopLoopingRedirect = true;
        if (errorDesc.indexOf("AADB2C90118") > -1) {
          // shouldn't reach this due to the code above.
        }
        if (errorDesc.indexOf("AADB2C90091") > -1) {
          login();
        }
        login();
      } else {
        // if the authority is forgot password, switch back to original
        if (window.msal.authority === forgotPasswordAuthority) {
          window.msal.authority = authority;
          removeItem(forgotPasswordKey);
        }
        removeItem(B2CErrorsKey);
        acquireToken();
      }
    }

    if (!scopes || scopes.length === 0) {
      // console.log("To obtain access tokens you must specify one or more scopes. See https://docs.microsoft.com/en-us/azure/active-directory-b2c/active-directory-b2c-access-tokens");
      state.stopLoopingRedirect = true;
    }
    state.scopes = { scopes };

    const msalConfig = {
      auth: {
        clientId: config.applicationId,
        authority,
        redirectUri,
        validateAuthority,
        postLogoutRedirectUri: config.postLogoutRedirectUri,
        navigateToLoginRequestUrl: false,
      },
      cache: {
        cacheLocation: config.cacheLocation, // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
      },
      system: {
        logger,
      },
    };

    // Create UserAgentApplication instance
    try{
      const msalInstance = new Msal.UserAgentApplication(msalConfig);
      msalInstance.handleRedirectCallback(authCallback);
    // eslint-disable-next-line no-empty
    } catch { }

  },
  run: (launchApp) => {
    state.launchApp = launchApp;
    if (!window.msal.isCallback(window.location.hash) && window.parent === window && !window.opener) {
      if (!state.stopLoopingRedirect) {
        acquireToken();
      }
    }
  },
  required: (WrappedComponent, renderLoading) => class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        signedIn: false,
        error: null,
      };
    }

    UNSAFE_componentWillMount() {
      acquireToken(() => {
        this.setState({
          signedIn: true,
        });
      });
    }

    render() {
      if (this.state.signedIn) {
        return (<WrappedComponent {...this.props} />);
      }
      return typeof renderLoading === "function" ? renderLoading() : null;
    }
  },
  signOut: () => logout(),
  getAccessToken: () => state.accessToken,
  getIdToken: () => state.idToken,
  refreshAccessToken: () => refreshAccessToken(),
  signedIn: () => signedIn(),
  login: (forceLogin = false) => login(forceLogin),
  register: (config) => register(config),
};

export default authentication;

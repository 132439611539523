import { ApiActions } from "../middleware/call-api";
import { ActionTypes } from "../reducers/validate-code";

export const validateHcfStaffCodeForConsent = (code) => {
  return ({
    [ApiActions.FETCH_FROM_API]: {
      endpoint: `/hcf/validateStaffCodeForConsent/${code}`,
      types: [
        ActionTypes.VALIDATING_CODE,
        ActionTypes.VALIDATED_CODE,
        ActionTypes.VALIDATING_CODE_FAILED,
      ],
    },
  });
};

export const validateSlhStaffCode = (code) => {
  return ({
    [ApiActions.FETCH_FROM_API]: {
      endpoint: `/hcf/validateSlhStaffCode/${code}`,
      types: [
        ActionTypes.VALIDATING_CODE,
        ActionTypes.VALIDATED_CODE,
        ActionTypes.VALIDATING_CODE_FAILED,
      ],
    },
  });
};
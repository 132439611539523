import amplitude from "amplitude-js";

const PARTNER_CODE = process.env.REACT_APP_WEBSITE_PARTNER_CODE;

export const SendClickedOnboardingAuthenticateEvent = () => {
  const eventName = `Opened ${PARTNER_CODE} Onboarding Portal`;
  amplitude.getInstance().logEvent(eventName);
};

export const SendStartedSignUpEvent = () => {
  const eventName = `Started ${PARTNER_CODE} Snug account signup`;
  amplitude.getInstance().logEvent(eventName);
};

export const SendStartedSignInEvent = () => {
  const eventName = `Started ${PARTNER_CODE} Snug account login`;
  amplitude.getInstance().logEvent(eventName);
};

export const SendStartedForgotPasswordEvent = () => {
  const eventName = `Started ${PARTNER_CODE} Snug forgot password flow`;
  amplitude.getInstance().logEvent(eventName);
};

export const SendSuccessfullyAuthenticatedEvent = () => {
  const eventName = `Successfully Authenticated ${PARTNER_CODE} Snug Account`;
  amplitude.getInstance().logEvent(eventName);
};

export const SendAuthenticationFailedEvent = (errorMessage) => {
  const eventName = `Authenticating ${PARTNER_CODE} Snug Account Failed`;
  const eventProperties = { Error: errorMessage };
  amplitude.getInstance().logEvent(eventName, eventProperties);
};

export const SendClickedAppDownloadEvent = (platform) => {
  const eventName = `Clicked ${PARTNER_CODE} Snug Download link`;
  const eventProperties = { DownloadPlatform: platform };
  amplitude.getInstance().logEvent(eventName, eventProperties);
};

import React from "react";
import { makeStyles } from "@material-ui/styles";
import LoadingState from "./loading-state";
import WellbeingHeader from "./wellbeing-header";

const useStyles = makeStyles(() => ({
  container: {
    textAlign: "center",
    margin: "0 auto",
    marginTop: 30,
    padding: 32,
  },

  poweredBySnugLogo: {
    display: "block",
    margin: "50px auto 0px",
  }
}));

function WellbeingPageLayout(props) {
  const { content, loading, error } = props;
  let ContentComponent = () => null;

  if (loading) {
    ContentComponent = (<LoadingState />);
  } else if (error) {
    ContentComponent = (<div>{error}</div>);
  } else {
    ContentComponent = content;
  }
  const classes = useStyles();
  return (
    <div>
      <link href="https://fonts.googleapis.com/css?family=Rubik&display=swap" rel="stylesheet" />
      <WellbeingHeader />
      <div className={classes.container}>
        {ContentComponent && ContentComponent}
      </div>
      <img className={classes.poweredBySnugLogo}
					src="/assets/images/poweredBySnug.png" />
    </div>
  );
}

export default WellbeingPageLayout;

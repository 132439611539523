import React from "react";
import DefaultPageLayout from "../components/default-page-layout";
import WellbeingPageLayout from "../components/wellbeing-page-layout";

function BasePageLayout(props) {
  if (process.env.REACT_APP_WEBSITE_RUNTIME_VARIANT === "wellbeing") {
    return (
      <WellbeingPageLayout {...props} />
    );
  }
  else {
    return (
      <DefaultPageLayout {...props} />
    );
  }
}

export default BasePageLayout;
